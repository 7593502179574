import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      smallHeight: "360",
      largeHeight: "420",
      smallWidth: "280",
      largeWidth: "336",
      snackbar: {
        content: "",
        color: "",
        icon: "",
        isVisible: false,
      },
      userEmail: "",
      userName: "",
      reportData: null,
    };
  },
  mutations: {
    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
    closeSnackbar(state) {
      state.snackbar.isVisible = false;
    },
    setReportData(state, data) {
      state.reportData = data;
    },
  },
  actions: {},
  modules: {},
  getters: {
    getSnackbar: (state) => {
      return state.snackbar;
    },
  },
});

export default store;
