let apiUrl = process.env.VUE_APP_WEB_URL;

console.log("apiurl", apiUrl);

import axios from "axios";

export default {
  async apiCallWithData(url, data) {
    return await axios
      .post(apiUrl + url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  async apiCallWithHeader(url) {
    return await axios
      .get(apiUrl + url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
};
