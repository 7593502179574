<template>
  <div class="body">
    <div v-if="config">
      <PowerBIReportEmbed :embedConfig="config"></PowerBIReportEmbed>
    </div>
  </div>
</template>
<script>
import { PowerBIReportEmbed } from "powerbi-client-vue-js";
import * as powerbi from "powerbi-client";
// import { encryptData, decryptData } from "../utils/encryption.js";
import apiservice from "@/services/apiservice.js";

export default {
  components: { PowerBIReportEmbed },

  data() {
    return {
      isMobile: false,
      config: null,
      reportId: "",
      powerBiDivId: "powerbi1212",
      configData: null,
      reportData: {
        clientName: "",
        portfolioName: "",
      },
    };
  },
  watch: {
    detectMobile(newValue) {
      this.isMobile = newValue;
      window.location.reload();
    },
  },
  mounted() {
    const isMobile = this.detectMobile(); // Initial call
    window.addEventListener("resize", this.handleResize);
    this.initializePowerBIConfig(isMobile);
    // this.getMyDashboard();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },

  async created() {
    // this.configData = await this.getMyDashboard();
    // setTimeout(() => {}, 3000);
    // Your code that should run before HTML page and components load

    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const data = {};
    for (const [key, value] of params.entries()) {
      data[key] = value;
    }
    const jsonData = JSON.stringify(data.token);
    // const reportId = data.rt;
    // this.reportId = reportId;
    // localStorage.setItem("reportId", reportId);
    if (jsonData) {
      var dataWithoutQuotes = jsonData.replace(/"/g, "");
    }

    const accessToken = dataWithoutQuotes;
    if (accessToken) {
      // console.log("accessToken", accessToken);
      localStorage.setItem("token", accessToken);

      this.$router.replace("/");
    }
  },
  methods: {
    updatePowerBIConfig(isMobile, id) {
      const config = {
        type: "report",
        id: id,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.configData.reportId}&autoAuth=false&ctid=19b2ab20-d880-41a2-b93d-61896b73131f`,
        accessToken: this.configData.accessToken,
        tokenType: powerbi.models.TokenType.Embed,
        settings: {
          filters: true,
          layoutType: isMobile
            ? powerbi.models.LayoutType.MobilePortrait
            : undefined,
        },
      };

      this.config = config;
    },
    detectMobile() {
      // console.log("detect");
      const userAgent = navigator.userAgent;
      // console.log("userAgent", userAgent);
      const mobileKeywords = [
        "Android",
        "iPhone",
        "iPad",
        "iPod",
        "Windows Phone",
        "BlackBerry",
        "Kindle",
        "Silk",
        "Opera Mini",
        "Opera Mobi",
      ];

      // Check if the user agent contains any mobile keywords
      return (
        mobileKeywords.some((keyword) => userAgent.includes(keyword)) &&
        window.innerWidth < 768
      );
    },
    handleResize() {
      this.isMobile = this.detectMobile();
    },
    async initializePowerBIConfig(isMobile) {
      this.configData = await this.getMyDashboard();
      if (this.configData) {
        this.updatePowerBIConfig(isMobile, this.configData.reportId); // Then set the config
      } else {
        await this.$store.commit("setSnackbar", {
          content: "something went wrong",
          icon: "mdi-alert-circle",
          color: "error ",
          isVisible: true,
        });
        window.location.href = "https://test.blue-horizon.com/login-form";
      }
      // Load configData first
    },

    async getMyDashboard() {
      if (localStorage.getItem("token")) {
        const reportCategory = "Interest Report";
        const result = await apiservice.apiCallWithHeader(
          `/api/report/token?reportCategory=${encodeURIComponent(
            reportCategory
          )}`
        );
        console.log("result", result);
        if (result && result.status == 200 && result.data.value == true) {
          const reportData = {
            clientName: "",
            portfolioName: "",
          };
          reportData.clientName = result.data.data.clientName;
          reportData.portfolioName = result.data.data.portfolioName;
          this.$store.commit("setReportData", reportData);
          const res = result.data.data.embedUrlPerUser;
          console.log("res", res);

          const isReportId = result?.data?.data?.reportId;
          const isAccessToken = result?.data?.data?.embedToken;
          if (isReportId && isAccessToken) {
            return {
              reportId: result.data.data.reportId,
              accessToken: result.data.data.embedToken,
            };
          } else {
            await this.$store.commit("setSnackbar", {
              content: result.data.msg || "something went wrong",
              icon: "mdi-alert-circle",
              color: "error ",
              isVisible: true,
            });
            window.location.href = "https://test.blue-horizon.com/login-form";
          }
          // const receiverDomain = "http://localhost:8080/";

          // Redirect to the receiver domain with the query string
          // window.location.href = `${receiverDomain}?token=${encodeURIComponent(
          //   result.data.data.embedToken
          // )}&at=${encodeURIComponent(
          //   localStorage.getItem("token")
          // )}&rt=${encodeURIComponent(result.data.data.reportId)}`;
        } else {
          console.log("iam else");
          await this.$store.commit("setSnackbar", {
            content: result.data.msg || "something went wrong",
            icon: "mdi-alert-circle",
            color: "error ",
            isVisible: true,
          });
          window.location.href = "https://test.blue-horizon.com/login-form";
        }
      }
    },
  },
};
</script>
<style>
#reportContainer {
  height: 100vh;
}
</style>
