// router.js
import { createRouter, createWebHistory } from "vue-router";
import reportEmbed from "../components/reportEmbed.vue";

// function guardMyroute(to, from, next) {
//   var isAuthenticated = false;
//   if (localStorage.getItem("accessToken")) {
//     isAuthenticated = true;
//   } else {
//     isAuthenticated = false;
//   }

//   if (isAuthenticated) {
//     next();
//   } else {
//     next("/");
//   }
// }

const routes = [
  {
    path: "/",
    name: "reportEmbed",
    component: reportEmbed,
    // beforeEnter: guardMyroute,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
