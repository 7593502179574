<template>
  <v-snackbar
    multi-line
    min-width="50"
    v-model="snackbarVisible"
    dark
    :timeout="1000"
    :color="snackbarColor"
    top
    right
    v-bind="$attrs"
    listeners
  >
    <v-layout align-center pr-4 class="pa-1">
      <v-icon class="pr-2" dark small>{{ snackbarIcon }}</v-icon>
      <v-layout column>
        <div>{{ snackbarContent }}</div>
      </v-layout>
    </v-layout>

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="closeSnackbar"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex"; // Import the store using the Vue 3 composition API

export default {
  name: "SnackBar",
  setup() {
    const store = useStore(); // Access the store using the useStore function

    const snackbarVisible = computed(() => store.state.snackbar.isVisible);
    const snackbarColor = computed(() => store.state.snackbar.color);
    const snackbarIcon = computed(() => store.state.snackbar.icon);
    const snackbarContent = computed(() => store.state.snackbar.content);

    const closeSnackbar = () => {
      store.commit("closeSnackbar");
    };

    return {
      snackbarVisible,
      snackbarColor,
      snackbarIcon,
      snackbarContent,
      closeSnackbar,
    };
  },
};
</script>
