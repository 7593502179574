<template>
  <div>
    <v-container fluid class="pt-1" style="padding-top: 0px">
      <v-row align="center" class="horizon-row">
        <div class="blue-horizon report mx-4" style="font-weight: 900">
          Blue Horizon
        </div>
        <!-- Logo -->
        <v-col xs="12" sm="4" md="3" class="blue-img" style="padding-top: 0px">
          <router-link to="/" class="pa-4" style="cursor: pointer">
            <v-img
              src="../assets/blueLogo.svg"
              alt="Logo"
              class="logo"
              width="200"
            />
          </router-link>
          <!-- <span class="report" style="font-size: 12px">Blue Horizon</span> -->
        </v-col>

        <!-- Report Info -->
        <v-col
          xs="12"
          sm="4"
          md="6"
          style="display: flex; place-self: center; padding-top: 0px"
        >
          <v-row justify="center" align="center">
            <div
              v-if="$matches.sm.min"
              class="mx-2 report"
              :class="$matches.sm.max ? 'report-mob' : ''"
            >
              {{ reportData ? reportData.clientName : "Loading..." }}
            </div>
            <div
              v-else
              class="mx-2 report"
              :class="$matches.sm.max ? 'report-mob mt-3' : ''"
            >
              {{ reportData ? reportData.clientName : "Loading..." }}
            </div>
            <!-- <div
              class="mx-2 report"
              :class="$matches.sm.max ? 'report-mob' : ''"
            >
              Account: CB-1015
            </div> -->
          </v-row>
        </v-col>

        <!-- Logout Button -->
        <v-col
          xs="12"
          sm="4"
          md="3"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 0px;
          "
        >
          <div>
            <div
              class="mx-2 report"
              :class="$matches.sm.max ? 'report-mob' : ''"
            >
              Account:
              {{ reportData ? reportData.portfolioName : "Loading..." }}
            </div>
          </div>
          <div @click="logout" class="logout-btn font-4 mx-4 float-right">
            Logout
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dataFromReport: this.reportData,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("reportId");
      this.$store.commit("setSnackbar", {
        content: "You have successfully logged out!",
        icon: "mdi-check-circle",
        color: "success",
        isVisible: true,
      });
      window.location.href = "https://test.blue-horizon.com";
    },
  },
  computed: {
    ...mapState(["reportData"]),
  },
};
</script>

<style lang="scss">
.logo {
  width: 100%;
  max-width: 150px;
}

.logout-btn {
  color: #1c3fd1;
  background-color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  box-shadow: none;
  cursor: pointer;
}

.report {
  color: #1c3fd1;
  font-weight: 500;
}
.report-mob {
  font-size: 10px;
  font-weight: 500;
}
@media only screen and (max-width: 280px) {
  .report-mob {
    font-size: 8px;
  }
}
@media only screen and (max-width: 320px) {
  .blue-horizon {
    display: block !important;
  }
  .blue-img {
    display: none !important;
  }
  .horizon-row {
    margin-top: 4px;
  }
}
.blue-horizon {
  display: none;
}
.blue-img {
  display: block;
}
</style>
