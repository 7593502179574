<template>
  <v-app>
    <v-main>
      <header-bar />
      <snack-bar />
      <!-- <router-view /> -->
      <report-embed />
    </v-main>
  </v-app>
</template>

<script>
import HeaderBar from "@/components/headerBar.vue";
import ReportEmbed from "@/components/reportEmbed.vue";
import SnackBar from "./components/snackBar.vue";
export default {
  name: "App",
  components: {
    ReportEmbed,
    HeaderBar,
    SnackBar,
  },

  data: () => ({
    //
  }),
};
</script>
